import type { ComputedRef } from 'vue'
import { ref, computed } from 'vue'

type StorageType = 'localStorage' | 'sessionStorage'

export const usePersistedSettings = (storageType: StorageType = 'localStorage') => {
  const storage = ref<Storage>()

  onMounted(() => {
    storage.value = (storageType === 'localStorage' ? window.localStorage : window.sessionStorage)
  })

  // Save a boolean value
  const saveBoolean = (key: string, value: boolean): void => {
    storage.value?.setItem(key, `${value}`)
  }

  // Load a boolean value
  const loadBoolean = (key: string, fallback: boolean): boolean => {
    const setting = storage.value?.getItem(key)
    if (setting === 'true') {
      return true
    }
    if (setting === 'false') {
      return false
    }
    return fallback
  }

  // Save a string value
  const saveString = (key: string, value: string): void => {
    storage.value?.setItem(key, value)
  }

  // Load a string value
  const loadString = (key: string, fallback: string): string => {
    const setting = storage.value?.getItem(key)
    return setting || fallback
  }

  // Save a number value
  const saveNumber = (key: string, value: number): void => {
    storage.value?.setItem(key, `${value}`)
  }

  // Load a number value
  const loadNumber = (key: string, fallback: number): number => {
    const setting = storage.value?.getItem(key)
    if (!setting) {
      return fallback
    }

    const parsedValue = parseInt(setting, 10)
    if (!Number.isNaN(parsedValue)) {
      return parsedValue
    }

    return fallback
  }

  // Save a generic value (string, boolean, number, undefined, array, or object)
  const saveValue = (key: string, value: string | boolean | number | undefined | any[]): void => {
    if (typeof value === 'boolean') {
      saveBoolean(key, value)
    } else if (typeof value === 'number') {
      saveNumber(key, value)
    } else if (typeof value === 'string') {
      saveString(key, value)
    } else if (Array.isArray(value)) {
      saveString(key, JSON.stringify(value))
    } else if (typeof value === 'object') {
      saveString(key, JSON.stringify(value))
    } else if (value === undefined) {
      storage.value?.removeItem(key)
    }
  }

  // Load a generic value (string, boolean, number, array, or object)
  const loadValue = <T>(key: string, value: T | string = 'not set'): ComputedRef<T> => {
    const computedValue = computed(() => {
      const setting = storage.value?.getItem(key)
      if (typeof value === 'boolean') {
        return loadBoolean(key, value)
      }
      if (typeof value === 'number') {
        return loadNumber(key, value)
      }
      if (Array.isArray(value) || typeof value === 'object') {
        try {
          if (setting === null || setting === undefined) {
            return value
          }
          const parsedValue = JSON.parse(setting)
          return parsedValue !== null ? parsedValue : value
        } catch (error) {
          return value
        }
      }
      return loadString(key, value as string)
    })

    return computedValue
  }

  // Remove a value from storage
  const removeValue = (key: string): void => {
    storage.value?.removeItem(key)
  }

  const loadRef = (ref: Ref, key: string) => {
    ref.value = loadValue<typeof ref.value>(key, ref.value).value
  }

  return {
    saveValue,
    loadValue,
    removeValue,
    loadRef,
  }
}
